<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        Czy na pewno chcesz usunąć tę pozycję?
      </div>
    </template>
    <template #submit>
      <v-btn
        v-if="dialog.data.deleteFileType"
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="confirmFileDelete()"
        @click="confirmFileDelete()"
      >
        Usuń Plik
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="confirmItemDelete()"
        @click="confirmItemDelete()"
      >
        Usuń
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      departmentId: state => state.price.departmentId,
      isProcessing (state) {
        const tableName = this.dialog.data.tableName
        return state[tableName].isProcessing
      }
    }),
  },
  methods: {
    ...mapActions({
      deleteOrderFile: 'order/deleteOrderFile',
      deleteBDOFile: 'order/deleteBDOFile',
      deleteClientFile: 'client/deleteClientFile',
      closeDialog: 'layout/closeDialog',
      closeSidebar: 'layout/closeSidebar',
      deleteItem (dispatch, payload) {
        return dispatch(`${payload.tableName}/deleteItem`, payload)
      }
    }),
    confirmFileDelete () {
      const { item, orderId, data } = this.dialog
      if (data.deleteFileType === 'kpo') {
        this.deleteBDOFile({ fileId: item.id, orderId })
          .then(() => { this.afterFormSubmitted('Usunięto kartę') })
      } else if (data.deleteFileType === 'clientFile') {
        this.deleteClientFile(item.id)
          .then(() => { this.afterFormSubmitted('Usunięto dokument') })
      } else {
        this.deleteOrderFile(item.id)
          .then(() => { this.afterFormSubmitted('Usunięto plik') })
      }
    },
    confirmItemDelete () {
      const { tableName, endpoint, redirect } = this.dialog.data || {}
      const { id } = this.dialog.item || {}

      const params = {
        id,
        tableName,
        params: {
          endpoint
        }
      }

      this.deleteItem(params)
        .then(() => {
          if (redirect) {
            this.$router.go(-1)
          }
          this.closeDialog()
          if (this.$route.name !== 'clients') {
            this.closeSidebar(tableName)
          }
        })
    }
  }
}
</script>
